import React, { useState, useEffect } from 'react';
import { Lock, AlertCircle } from 'lucide-react';
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useNavigate } from 'react-router-dom';

interface PaymentStepProps {
  salePrice: number;
  onBack: () => void;
}

const PaymentStep: React.FC<PaymentStepProps> = ({ salePrice, onBack }) => {
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const paypalOptions = {
    "client-id": import.meta.env.VITE_PAYPAL_CLIENT_ID || "",
    currency: "USD",
    intent: "capture"
  };

  return (
    <div className="space-y-6">
      <div className="text-center mb-8">
        <h3 className="text-2xl font-bold text-white mb-2">Complete Your Order</h3>
        <p className="text-gray-400">Total: ${salePrice}</p>
      </div>

      {error && (
        <div className="bg-red-500/10 border border-red-500 rounded-lg p-4 mb-6 flex items-center">
          <AlertCircle className="w-5 h-5 text-red-500 mr-2 flex-shrink-0" />
          <p className="text-red-500 text-sm">{error}</p>
        </div>
      )}

      <PayPalScriptProvider options={paypalOptions}>
        <PayPalButtons
          style={{
            layout: "vertical",
            color: "gold",
            shape: "rect",
            label: "pay"
          }}
          createOrder={(_data, actions) => {
            return actions.order.create({
              purchase_units: [{
                amount: {
                  value: salePrice.toString(),
                  currency_code: "USD",
                  breakdown: {
                    item_total: {
                      value: salePrice.toString(),
                      currency_code: "USD"
                    }
                  }
                },
                description: "Landing Page Package - Black Friday Special",
                items: [{
                  name: "Premium Landing Page",
                  description: "Custom-designed responsive landing page",
                  unit_amount: {
                    value: salePrice.toString(),
                    currency_code: "USD"
                  },
                  quantity: "1"
                }]
              }]
            });
          }}
          onApprove={async (data, actions) => {
            try {
              const order = await actions.order?.capture();
              console.log("Payment successful!", order);
              navigate('/thank-you');
            } catch (err) {
              console.error("Payment error:", err);
              setError("There was a problem processing your payment. Please try again.");
            }
          }}
          onError={(err) => {
            console.error("PayPal error:", err);
            setError("There was a problem connecting to PayPal. Please try again.");
          }}
          onCancel={() => {
            setError("Payment cancelled. Please try again when you're ready.");
          }}
        />
      </PayPalScriptProvider>

      <div className="flex items-center justify-center space-x-4">
        <button
          onClick={onBack}
          className="px-6 py-3 bg-gray-800 text-white rounded-lg hover:bg-gray-700 transition-colors"
        >
          Back
        </button>
      </div>

      <div className="text-center text-sm text-gray-400 space-y-2 mt-6">
        <p className="flex items-center justify-center">
          <Lock className="w-4 h-4 mr-2" />
          Secure 256-bit SSL encrypted payment
        </p>
        <p>Your information is protected and never stored</p>
      </div>
    </div>
  );
};

export default PaymentStep;