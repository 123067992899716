export interface Template {
  id: string;
  title: string;
  description: string;
  image: string;
  features: string[];
  industry: string;
  conversionRate: string;
  uniqueFeatures?: string;
  interactiveElements: string[];
  theme: {
    primary: string;
    secondary: string;
    accent: string;
    background: string;
    text: string;
    style: 'minimal' | 'bold' | 'elegant' | 'playful' | 'corporate' | 'luxurious';
  };
}

export const templates: Template[] = [
  {
    id: 'saas-landing',
    title: 'SaaS Pro',
    description: 'High-converting SaaS landing page with modern design',
    image: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&q=80',
    features: [
      'AI-powered chatbot integration',
      'Dynamic feature showcase with 3D animations',
      'Smart pricing tables with ROI calculator',
      'Social proof wall with live updates',
      'Multi-step demo booking system'
    ],
    industry: 'SaaS / Technology',
    conversionRate: '12.4%',
    uniqueFeatures: 'AI Integration',
    interactiveElements: [
      'Live chat with AI assistant',
      'Interactive pricing calculator',
      'Feature comparison tool',
      'ROI estimator',
      'One-click demo scheduler'
    ],
    theme: {
      primary: 'indigo-600',
      secondary: 'sky-500',
      accent: 'violet-500',
      background: 'slate-900',
      text: 'gray-100',
      style: 'minimal'
    }
  },
  {
    id: 'restaurant-site',
    title: 'Restaurant Elite',
    description: 'Beautiful restaurant website with online ordering',
    image: 'https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?auto=format&fit=crop&q=80',
    features: [
      'Real-time table availability checker',
      '3D virtual restaurant tour',
      'Interactive digital menu with AR view',
      'Smart reservation system with AI concierge',
      'Integration with delivery platforms'
    ],
    industry: 'Restaurant / Hospitality',
    conversionRate: '8.7%',
    uniqueFeatures: 'AR Menu View',
    interactiveElements: [
      'AR dish preview',
      'Virtual tour navigation',
      'Live table booking',
      'Menu customization',
      'Order tracking system'
    ],
    theme: {
      primary: 'amber-600',
      secondary: 'red-700',
      accent: 'emerald-500',
      background: 'stone-900',
      text: 'stone-100',
      style: 'elegant'
    }
  },
  {
    id: 'ecommerce-fashion',
    title: 'Fashion Store',
    description: 'Modern e-commerce template for fashion brands',
    image: 'https://images.unsplash.com/photo-1441986300917-64674bd600d8?auto=format&fit=crop&q=80',
    features: [
      'Virtual try-on with AR technology',
      'AI size recommendation system',
      'Dynamic product visualization',
      'Personalized style suggestions',
      'Social shopping integration'
    ],
    industry: 'E-commerce / Fashion',
    conversionRate: '4.2%',
    uniqueFeatures: 'Virtual Try-On',
    interactiveElements: [
      'AR clothing preview',
      'Size finder tool',
      'Style matcher',
      'Outfit builder',
      'Social sharing hub'
    ],
    theme: {
      primary: 'rose-500',
      secondary: 'pink-400',
      accent: 'fuchsia-500',
      background: 'zinc-900',
      text: 'zinc-100',
      style: 'playful'
    }
  },
  {
    id: 'travel-blog',
    title: 'Travel Explorer',
    description: 'Content-focused blog with immersive photography',
    image: 'https://images.unsplash.com/photo-1469854523086-cc02fe5d8800?auto=format&fit=crop&q=80',
    features: [
      '360° destination previews',
      'Interactive travel planning tools',
      'AI-powered trip recommendations',
      'Real-time weather integration',
      'Community features with gamification'
    ],
    industry: 'Travel / Lifestyle',
    conversionRate: '6.8%',
    uniqueFeatures: '360° Views',
    interactiveElements: [
      'Virtual destination tours',
      'Trip planner',
      'Weather radar',
      'Community dashboard',
      'Achievement system'
    ],
    theme: {
      primary: 'teal-500',
      secondary: 'cyan-400',
      accent: 'orange-500',
      background: 'gray-900',
      text: 'gray-100',
      style: 'bold'
    }
  },
  {
    id: 'fitness-app',
    title: 'Fitness Pro',
    description: 'Mobile-first workout tracking application',
    image: 'https://images.unsplash.com/photo-1517836357463-d25dfeac3438?auto=format&fit=crop&q=80',
    features: [
      'AI form correction technology',
      'Real-time performance analytics',
      'Smart workout recommendations',
      'Progress visualization with AR',
      'Social challenges platform'
    ],
    industry: 'Health / Fitness',
    conversionRate: '9.3%',
    uniqueFeatures: 'AI Form Correction',
    interactiveElements: [
      'Workout simulator',
      'Progress tracker',
      'Challenge creator',
      'Nutrition planner',
      'Community feed'
    ],
    theme: {
      primary: 'emerald-500',
      secondary: 'lime-500',
      accent: 'yellow-400',
      background: 'gray-900',
      text: 'gray-100',
      style: 'bold'
    }
  },
  {
    id: 'real-estate',
    title: 'Property Showcase',
    description: 'Property listing site with virtual tours',
    image: 'https://images.unsplash.com/photo-1560518883-ce09059eeffa?auto=format&fit=crop&q=80',
    features: [
      'Virtual property walkthrough',
      'AI-powered price prediction',
      'Neighborhood analytics dashboard',
      'Smart property matching system',
      'Interactive mortgage calculator'
    ],
    industry: 'Real Estate',
    conversionRate: '5.6%',
    uniqueFeatures: 'Virtual Walkthrough',
    interactiveElements: [
      '3D property viewer',
      'Price estimator',
      'Area explorer',
      'Mortgage calculator',
      'Viewing scheduler'
    ],
    theme: {
      primary: 'blue-600',
      secondary: 'slate-500',
      accent: 'amber-400',
      background: 'slate-900',
      text: 'gray-100',
      style: 'corporate'
    }
  }
];