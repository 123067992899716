import React, { useState, useEffect } from 'react';
import Hero from '../components/Hero';
import BlackFridayBanner from '../components/BlackFridayBanner';
import Navigation from '../components/Navigation';
import TemplateShowcase from '../components/TemplateShowcase';
import PricingSection from '../components/sections/PricingSection';
import AIFeaturesSection from '../components/sections/AIFeaturesSection';
import ContactSection from '../components/sections/ContactSection';
import AIChat from '../components/AIChat';

const MainLayout: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    // Handle hash links on page load
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="min-h-screen bg-gray-900">
      <Navigation
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        isScrolled={isScrolled}
      />
      <main>
        <Hero />
        <BlackFridayBanner />
        <div id="templates">
          <TemplateShowcase />
        </div>
        <div className="relative z-10">
          <BlackFridayBanner />
        </div>
        <div id="ai-features">
          <AIFeaturesSection />
        </div>
        <div className="relative z-10">
          <BlackFridayBanner />
        </div>
        <div id="pricing">
          <PricingSection />
        </div>
        <div id="contact">
          <ContactSection />
        </div>
        <AIChat />
      </main>
    </div>
  );
};

export default MainLayout;