import React, { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null
  };

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      const isFirebaseError = this.state.error?.message.includes('Firebase');
      const isEnvVarError = this.state.error?.message.includes('environment variables');

      return (
        <div className="min-h-screen flex items-center justify-center bg-gray-900 p-4">
          <div className="max-w-md w-full bg-gray-800 rounded-lg shadow-xl p-8">
            <h2 className="text-2xl font-bold text-white mb-4">
              {isFirebaseError ? 'Firebase Configuration Error' : 'Application Error'}
            </h2>
            <div className="text-gray-300 space-y-4">
              {isEnvVarError ? (
                <>
                  <p>Missing Firebase configuration. Please ensure all required environment variables are set:</p>
                  <ul className="list-disc list-inside space-y-1 text-sm">
                    <li>VITE_FIREBASE_API_KEY</li>
                    <li>VITE_FIREBASE_AUTH_DOMAIN</li>
                    <li>VITE_FIREBASE_PROJECT_ID</li>
                    <li>VITE_FIREBASE_STORAGE_BUCKET</li>
                    <li>VITE_FIREBASE_MESSAGING_SENDER_ID</li>
                    <li>VITE_FIREBASE_APP_ID</li>
                  </ul>
                </>
              ) : (
                <p>An error occurred while loading the application. Please try again later.</p>
              )}
              {import.meta.env.DEV && (
                <div className="mt-4 p-4 bg-gray-900 rounded-lg">
                  <p className="text-red-400 text-sm font-mono">{this.state.error?.message}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}