import React, { useState } from 'react';
import { Sparkles, Clock, ArrowDown } from 'lucide-react';
import BlackFridayCheckout from './BlackFridayCheckout';
import { useAppStore } from '../store';

const BlackFridayBanner: React.FC = () => {
  const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);
  const { spotsRemaining, decrementSpots } = useAppStore();

  const handleOpenCheckout = () => {
    setIsCheckoutOpen(true);
    decrementSpots();
  };

  return (
    <>
      <div className="relative bg-gradient-to-r from-gray-900 via-purple-900 to-gray-900 text-white py-6 overflow-hidden">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_50%,rgba(120,119,198,0.2)_0%,rgba(0,0,0,0)_50%)] animate-pulse-slow"></div>
        <div className="max-w-7xl mx-auto px-4 relative">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="flex items-center space-x-4">
              <Sparkles className="w-6 h-6 text-yellow-400 animate-pulse" />
              <div>
                <div className="flex items-center space-x-2">
                  <span className="font-bold text-yellow-400">BLACK FRIDAY SPECIAL</span>
                  <div className="flex items-center space-x-1 bg-purple-900/50 px-3 py-1 rounded-full">
                    <Clock className="w-4 h-4 text-purple-400" />
                    <span className="text-sm text-purple-300">Limited Time Offer</span>
                  </div>
                </div>
                <h3 className="text-2xl md:text-3xl font-bold mt-1">
                  Get Your Landing Page for just{' '}
                  <span className="text-yellow-400">$399!</span>
                </h3>
              </div>
            </div>
            <div className="mt-4 md:mt-0 flex flex-col items-center">
              <div className="text-sm text-gray-300 mb-2">Regular Price: <span className="line-through">$1,600</span></div>
              <div className="flex flex-col items-center">
                <ArrowDown className="w-5 h-5 text-yellow-400 animate-bounce mb-2" />
                <button
                  onClick={handleOpenCheckout}
                  className="px-8 py-3 bg-yellow-400 text-black font-bold rounded-full hover:bg-yellow-300 transition-all duration-300 transform hover:-translate-y-1 shadow-lg hover:shadow-xl"
                >
                  Claim Your 75% Discount
                </button>
              </div>
              <div className="text-sm text-purple-300 mt-2">
                Only {spotsRemaining} spots remaining at this price!
              </div>
            </div>
          </div>
        </div>
      </div>

      {isCheckoutOpen && (
        <div className="relative">
          <BlackFridayCheckout onClose={() => setIsCheckoutOpen(false)} />
        </div>
      )}
    </>
  );
};

export default BlackFridayBanner;