import React from 'react';
import LegalLayout from './LegalLayout';

const TermsOfService: React.FC = () => {
  return (
    <LegalLayout>
      <div className="prose prose-invert max-w-none">
        <h1 className="text-4xl font-bold text-white mb-8">Terms of Service</h1>
        <p className="text-gray-300">Last updated: March 11, 2024</p>

        <section className="mt-8">
          <h2 className="text-2xl font-bold text-white mb-4">1. Agreement to Terms</h2>
          <div className="space-y-4 text-gray-300">
            <p>By accessing or using our services, you agree to be bound by these Terms of Service and our Privacy Policy.</p>
          </div>
        </section>

        <section className="mt-8">
          <h2 className="text-2xl font-bold text-white mb-4">2. Services</h2>
          <div className="space-y-4 text-gray-300">
            <p>We provide web design and development services, including:</p>
            <ul className="list-disc pl-6">
              <li>Custom website design and development</li>
              <li>AI-powered features and integrations</li>
              <li>Ongoing maintenance and support</li>
              <li>Consulting services</li>
            </ul>
          </div>
        </section>

        <section className="mt-8">
          <h2 className="text-2xl font-bold text-white mb-4">3. Payment Terms</h2>
          <div className="space-y-4 text-gray-300">
            <p>Payment terms include:</p>
            <ul className="list-disc pl-6">
              <li>All prices are in USD unless otherwise stated</li>
              <li>Payment is required before work begins</li>
              <li>Refunds are subject to our refund policy</li>
              <li>Late payments may incur additional fees</li>
            </ul>
          </div>
        </section>

        <section className="mt-8">
          <h2 className="text-2xl font-bold text-white mb-4">4. Intellectual Property</h2>
          <div className="space-y-4 text-gray-300">
            <p>Ownership and rights:</p>
            <ul className="list-disc pl-6">
              <li>You retain ownership of your content</li>
              <li>We retain ownership of our proprietary code and systems</li>
              <li>Third-party assets are subject to their respective licenses</li>
            </ul>
          </div>
        </section>

        <section className="mt-8">
          <h2 className="text-2xl font-bold text-white mb-4">5. Limitation of Liability</h2>
          <div className="space-y-4 text-gray-300">
            <p>We are not liable for:</p>
            <ul className="list-disc pl-6">
              <li>Indirect or consequential damages</li>
              <li>Loss of profits or revenue</li>
              <li>Data loss or corruption</li>
              <li>Third-party services or integrations</li>
            </ul>
          </div>
        </section>

        <section className="mt-8">
          <h2 className="text-2xl font-bold text-white mb-4">6. Contact Information</h2>
          <div className="space-y-4 text-gray-300">
            <p>For questions about these Terms, please contact us at:</p>
            <p>Email: legal@craftededgestudio.com</p>
          </div>
        </section>
      </div>
    </LegalLayout>
  );
};

export default TermsOfService;