import React, { useState } from 'react';
import { ArrowRight, Check, Star, Bot, LineChart, Users } from 'lucide-react';

const SaasPreview: React.FC = () => {
  const [showDemo, setShowDemo] = useState(false);
  const [showPricing, setShowPricing] = useState(false);

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-purple-900 to-gray-900">
      {/* Hero Section */}
      <section className="pt-20 pb-32">
        <div className="max-w-6xl mx-auto px-4">
          <div className="text-center">
            <div className="inline-flex items-center px-4 py-2 bg-purple-600/20 rounded-full mb-6">
              <Bot className="w-5 h-5 text-purple-400 mr-2" />
              <span className="text-purple-300">AI-Powered Platform</span>
            </div>
            <h1 className="text-5xl md:text-6xl font-bold text-white mb-6">
              Streamline Your Workflow with{' '}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400">
                SaasFlow
              </span>
            </h1>
            <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
              The all-in-one platform that helps teams collaborate, manage projects, and deliver results faster than ever before.
            </p>
            <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
              <button 
                onClick={() => setShowDemo(true)}
                className="px-8 py-4 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-all"
              >
                Start Free Trial
              </button>
              <button 
                onClick={() => setShowDemo(true)}
                className="px-8 py-4 bg-white/10 text-white rounded-lg hover:bg-white/20 transition-all"
              >
                Watch Demo
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-20 bg-gray-900/50">
        <div className="max-w-6xl mx-auto px-4">
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                icon: Bot,
                title: 'Smart Automation',
                description: 'Automate repetitive tasks and focus on what matters most.'
              },
              {
                icon: LineChart,
                title: 'Real-time Analytics',
                description: 'Get instant insights into your team\'s performance.'
              },
              {
                icon: Users,
                title: 'Team Collaboration',
                description: 'Work together seamlessly across time zones.'
              }
            ].map((feature, index) => (
              <div key={index} className="p-6 bg-gray-800/50 rounded-lg hover:bg-gray-800 transition-colors cursor-pointer">
                <feature.icon className="w-8 h-8 text-purple-400 mb-4" />
                <h3 className="text-xl font-semibold text-white mb-3">{feature.title}</h3>
                <p className="text-gray-300">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section className="py-20">
        <div className="max-w-6xl mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-white mb-4">Simple, Transparent Pricing</h2>
            <p className="text-gray-300">Choose the plan that's right for your team</p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                name: 'Starter',
                price: 29,
                features: ['5 Team Members', '10 Projects', 'Basic Analytics']
              },
              {
                name: 'Pro',
                price: 79,
                features: ['15 Team Members', 'Unlimited Projects', 'Advanced Analytics']
              },
              {
                name: 'Enterprise',
                price: 199,
                features: ['Unlimited Team Members', 'Priority Support', 'Custom Features']
              }
            ].map((plan, index) => (
              <div key={index} className="p-6 bg-gray-800/50 rounded-lg hover:bg-gray-800 transition-colors">
                <h3 className="text-xl font-semibold text-white mb-2">{plan.name}</h3>
                <div className="text-3xl font-bold text-white mb-4">
                  ${plan.price}
                  <span className="text-sm text-gray-400">/month</span>
                </div>
                <ul className="space-y-3 mb-6">
                  {plan.features.map((feature, i) => (
                    <li key={i} className="flex items-center text-gray-300">
                      <Check className="w-5 h-5 text-purple-400 mr-2" />
                      {feature}
                    </li>
                  ))}
                </ul>
                <button 
                  onClick={() => setShowPricing(true)}
                  className="w-full py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-all"
                >
                  Get Started
                </button>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Demo Modal */}
      {showDemo && (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-90">
          <div className="bg-gray-900 p-8 rounded-lg max-w-lg w-full">
            <h3 className="text-2xl font-bold text-white mb-4">Schedule a Demo</h3>
            <form className="space-y-4">
              <div>
                <label className="block text-gray-300 mb-2">Name</label>
                <input
                  type="text"
                  className="w-full px-4 py-2 bg-gray-800 text-white rounded-lg"
                />
              </div>
              <div>
                <label className="block text-gray-300 mb-2">Email</label>
                <input
                  type="email"
                  className="w-full px-4 py-2 bg-gray-800 text-white rounded-lg"
                />
              </div>
              <div>
                <label className="block text-gray-300 mb-2">Company Size</label>
                <select className="w-full px-4 py-2 bg-gray-800 text-white rounded-lg">
                  <option>1-10 employees</option>
                  <option>11-50 employees</option>
                  <option>51-200 employees</option>
                  <option>201+ employees</option>
                </select>
              </div>
              <div className="flex justify-end space-x-4 mt-6">
                <button
                  type="button"
                  onClick={() => setShowDemo(false)}
                  className="px-6 py-2 bg-gray-800 text-white rounded-lg"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-6 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
                >
                  Schedule Demo
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Pricing Modal */}
      {showPricing && (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-90">
          <div className="bg-gray-900 p-8 rounded-lg max-w-lg w-full">
            <h3 className="text-2xl font-bold text-white mb-4">Start Your Free Trial</h3>
            <form className="space-y-4">
              <div>
                <label className="block text-gray-300 mb-2">Email</label>
                <input
                  type="email"
                  className="w-full px-4 py-2 bg-gray-800 text-white rounded-lg"
                />
              </div>
              <div>
                <label className="block text-gray-300 mb-2">Password</label>
                <input
                  type="password"
                  className="w-full px-4 py-2 bg-gray-800 text-white rounded-lg"
                />
              </div>
              <div className="flex justify-end space-x-4 mt-6">
                <button
                  type="button"
                  onClick={() => setShowPricing(false)}
                  className="px-6 py-2 bg-gray-800 text-white rounded-lg"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-6 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
                >
                  Start Free Trial
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default SaasPreview;