import React, { useState } from 'react';
import { ShoppingBag, Heart, Search, Filter, Star, ArrowRight } from 'lucide-react';

const products = [
  {
    id: 1,
    name: 'Summer Dress',
    price: 89.99,
    image: 'https://images.unsplash.com/photo-1515372039744-b8f02a3ae446?auto=format&fit=crop&q=80',
    sizes: ['XS', 'S', 'M', 'L', 'XL'],
    colors: ['White', 'Black', 'Blue']
  },
  {
    id: 2,
    name: 'Classic Blazer',
    price: 129.99,
    image: 'https://images.unsplash.com/photo-1591369822096-ffd140ec948f?auto=format&fit=crop&q=80',
    sizes: ['S', 'M', 'L', 'XL'],
    colors: ['Black', 'Navy', 'Gray']
  },
  {
    id: 3,
    name: 'Leather Bag',
    price: 199.99,
    image: 'https://images.unsplash.com/photo-1590874103328-eac38a683ce7?auto=format&fit=crop&q=80',
    colors: ['Brown', 'Black', 'Tan']
  }
];

const EcommercePreview: React.FC = () => {
  const [favorites, setFavorites] = useState<number[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<number | null>(null);
  const [showCart, setShowCart] = useState(false);
  const [cartItems, setCartItems] = useState<number[]>([]);

  const toggleFavorite = (id: number) => {
    setFavorites(prev =>
      prev.includes(id)
        ? prev.filter(fid => fid !== id)
        : [...prev, id]
    );
  };

  const addToCart = (id: number) => {
    setCartItems(prev => [...prev, id]);
    setShowCart(true);
  };

  return (
    <div className="min-h-screen bg-gray-900">
      {/* Hero Section */}
      <section className="relative h-[60vh]">
        <div className="absolute inset-0">
          <img
            src="https://images.unsplash.com/photo-1445205170230-053b83016050?auto=format&fit=crop&q=80"
            alt="Fashion Banner"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent" />
        </div>
        <div className="relative z-10 h-full flex items-center">
          <div className="max-w-6xl mx-auto px-4">
            <h1 className="text-5xl md:text-6xl font-bold text-white mb-6">
              Summer Collection
              <br />
              <span className="text-rose-400">2024</span>
            </h1>
            <p className="text-xl text-gray-200 mb-8 max-w-xl">
              Discover the latest trends in fashion and explore our new collection
            </p>
            <button 
              onClick={() => window.scrollTo({ top: 800, behavior: 'smooth' })}
              className="px-8 py-4 bg-rose-600 text-white rounded-lg hover:bg-rose-700 transition-all"
            >
              Shop Now
            </button>
          </div>
        </div>
      </section>

      {/* Products Grid */}
      <section className="py-20">
        <div className="max-w-6xl mx-auto px-4">
          <div className="flex justify-between items-center mb-8">
            <h2 className="text-2xl font-bold text-white">Featured Products</h2>
            <button className="flex items-center text-gray-300 hover:text-white">
              <Filter className="w-5 h-5 mr-2" />
              Filter
            </button>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {products.map((product) => (
              <div key={product.id} className="group">
                <div className="relative overflow-hidden rounded-lg bg-gray-800">
                  <img
                    src={product.image}
                    alt={product.name}
                    className="w-full h-96 object-cover transform group-hover:scale-110 transition-transform duration-500"
                  />
                  <div className="absolute top-4 right-4 space-y-2">
                    <button
                      onClick={() => toggleFavorite(product.id)}
                      className="p-2 bg-white rounded-full hover:bg-gray-100 transition-all"
                    >
                      <Heart className={`w-5 h-5 ${
                        favorites.includes(product.id) ? 'text-rose-600 fill-current' : 'text-gray-900'
                      }`} />
                    </button>
                  </div>
                  <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black to-transparent opacity-0 group-hover:opacity-100 transition-opacity">
                    <button 
                      onClick={() => addToCart(product.id)}
                      className="w-full py-2 bg-rose-600 text-white rounded-lg hover:bg-rose-700 transition-all flex items-center justify-center"
                    >
                      <ShoppingBag className="w-5 h-5 mr-2" />
                      Add to Cart
                    </button>
                  </div>
                </div>
                <div className="mt-4">
                  <h3 className="text-lg font-semibold text-white">{product.name}</h3>
                  <div className="flex items-center justify-between mt-2">
                    <span className="text-rose-400">${product.price}</span>
                    <div className="flex text-rose-400">
                      {[...Array(5)].map((_, i) => (
                        <Star key={i} className="w-4 h-4 fill-current" />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Cart Modal */}
      {showCart && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4">
            <div className="fixed inset-0 bg-black opacity-75" onClick={() => setShowCart(false)} />
            <div className="relative bg-gray-900 rounded-lg max-w-md w-full p-8">
              <h3 className="text-2xl font-bold text-white mb-6">Shopping Cart</h3>
              {cartItems.map((id) => {
                const product = products.find(p => p.id === id);
                if (!product) return null;
                return (
                  <div key={id} className="flex items-center space-x-4 mb-4">
                    <img
                      src={product.image}
                      alt={product.name}
                      className="w-20 h-20 object-cover rounded"
                    />
                    <div>
                      <h4 className="text-white font-semibold">{product.name}</h4>
                      <p className="text-rose-400">${product.price}</p>
                    </div>
                  </div>
                );
              })}
              <div className="mt-6 flex justify-end space-x-4">
                <button
                  onClick={() => setShowCart(false)}
                  className="px-6 py-2 bg-gray-800 text-white rounded-lg"
                >
                  Continue Shopping
                </button>
                <button
                  className="px-6 py-2 bg-rose-600 text-white rounded-lg hover:bg-rose-700"
                >
                  Checkout
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EcommercePreview;