import React from 'react';
import { ArrowLeft, ArrowRight, X } from 'lucide-react';
import {
  SaasPreview,
  RestaurantPreview,
  EcommercePreview,
  TravelPreview,
  FitnessPreview,
  RealEstatePreview
} from './previews';
import { cn } from '../lib/utils';

interface TemplatePreviewProps {
  templateId: string;
  onClose: () => void;
}

const TemplatePreview: React.FC<TemplatePreviewProps> = ({ templateId, onClose }) => {
  const getPreviewComponent = () => {
    switch (templateId) {
      case 'restaurant-site':
        return <RestaurantPreview />;
      case 'saas-landing':
        return <SaasPreview />;
      case 'ecommerce-fashion':
        return <EcommercePreview />;
      case 'travel-blog':
        return <TravelPreview />;
      case 'fitness-app':
        return <FitnessPreview />;
      case 'real-estate':
        return <RealEstatePreview />;
      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 py-8">
        <div className="fixed inset-0 bg-black/90 transition-opacity" onClick={onClose} />
        
        <div className="relative w-full max-w-7xl bg-gray-900 rounded-lg shadow-xl overflow-hidden">
          {/* Preview Header */}
          <div className="sticky top-0 z-10 flex justify-between items-center p-4 bg-gray-900 border-b border-gray-800">
            <div className="flex space-x-2">
              <div className="w-3 h-3 rounded-full bg-red-500" />
              <div className="w-3 h-3 rounded-full bg-yellow-500" />
              <div className="w-3 h-3 rounded-full bg-green-500" />
            </div>
            <div className="flex items-center space-x-4">
              <button 
                className={cn(
                  "text-gray-400 hover:text-white transition-colors",
                  "focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-900"
                )}
              >
                <ArrowLeft className="w-5 h-5" />
              </button>
              <button 
                className={cn(
                  "text-gray-400 hover:text-white transition-colors",
                  "focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-900"
                )}
              >
                <ArrowRight className="w-5 h-5" />
              </button>
              <button
                onClick={onClose}
                className={cn(
                  "text-gray-400 hover:text-white transition-colors",
                  "focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-900"
                )}
              >
                <X className="w-6 h-6" />
              </button>
            </div>
          </div>

          {/* Preview Content */}
          <div className="overflow-y-auto max-h-[80vh]">
            {getPreviewComponent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplatePreview;