export interface AIFeature {
  id: string;
  name: string;
  description: string;
  price: number;
  salePrice: number;
  features: string[];
  icon: string;
  planId: string; // PayPal subscription plan ID
}

export const aiFeatures: AIFeature[] = [
  {
    id: 'ai-chatbot',
    name: 'AI Customer Support Chatbot',
    description: 'Intelligent chatbot that handles customer inquiries 24/7',
    price: 149,
    salePrice: 74,
    features: [
      'Custom trained on your business data',
      '24/7 automated customer support',
      'Multi-language support',
      'Seamless handoff to human agents',
      'Analytics dashboard',
      'Monthly model updates'
    ],
    icon: 'MessageSquare',
    planId: 'P-CHATBOT' // Replace with actual PayPal plan ID
  },
  {
    id: 'ai-order',
    name: 'AI Restaurant Order Assistant',
    description: 'Smart ordering system that enhances customer experience',
    price: 199,
    salePrice: 99,
    features: [
      'Natural language order processing',
      'Smart menu recommendations',
      'Dietary restriction handling',
      'Order customization support',
      'Integration with POS systems',
      'Order history analytics'
    ],
    icon: 'UtensilsCrossed',
    planId: 'P-ORDER' // Replace with actual PayPal plan ID
  },
  {
    id: 'ai-content',
    name: 'AI Content Generator',
    description: 'Automated content creation for your website',
    price: 124,
    salePrice: 62,
    features: [
      'SEO-optimized content generation',
      'Blog post creation',
      'Product descriptions',
      'Social media content',
      'Monthly content calendar',
      'Keyword optimization'
    ],
    icon: 'FileText',
    planId: 'P-CONTENT' // Replace with actual PayPal plan ID
  },
  {
    id: 'ai-analytics',
    name: 'AI Business Intelligence',
    description: 'Smart analytics and insights for your business',
    price: 174,
    salePrice: 87,
    features: [
      'Customer behavior analysis',
      'Predictive analytics',
      'Sales forecasting',
      'Trend identification',
      'Automated reporting',
      'Custom dashboards'
    ],
    icon: 'LineChart',
    planId: 'P-ANALYTICS' // Replace with actual PayPal plan ID
  }
];