import React, { useState } from 'react';
import { Clock, MapPin, Phone, Calendar, Star, ArrowRight } from 'lucide-react';

const RestaurantPreview: React.FC = () => {
  const [selectedDish, setSelectedDish] = useState<string | null>(null);
  const [showVirtualTour, setShowVirtualTour] = useState(false);
  const [showBooking, setShowBooking] = useState(false);
  const [currentRoom, setCurrentRoom] = useState(0);

  const dishes = [
    {
      id: 'coq-au-vin',
      name: 'Coq au Vin',
      price: 32,
      description: 'Braised chicken in red wine sauce with mushrooms',
      image: 'https://images.unsplash.com/photo-1600803907087-f56d462fd26b?auto=format&fit=crop&q=80',
      ingredients: ['Free-range chicken', 'Red wine', 'Mushrooms', 'Pearl onions'],
      preparationTime: '45 mins',
      calories: '650 kcal',
      allergens: ['Alcohol', 'Dairy']
    },
    {
      id: 'beef-bourguignon',
      name: 'Beef Bourguignon',
      price: 36,
      description: 'Classic French beef stew with red wine',
      image: 'https://images.unsplash.com/photo-1534939561126-855b8675edd7?auto=format&fit=crop&q=80',
      ingredients: ['Prime beef', 'Red wine', 'Bacon', 'Root vegetables'],
      preparationTime: '50 mins',
      calories: '780 kcal',
      allergens: ['Alcohol', 'Dairy']
    },
    {
      id: 'creme-brulee',
      name: 'Crème Brûlée',
      description: 'Classic vanilla custard with caramelized sugar',
      price: 14,
      image: 'https://images.unsplash.com/photo-1470124182917-cc6e71b22ecc?auto=format&fit=crop&q=80',
      ingredients: ['Vanilla bean', 'Heavy cream', 'Farm eggs', 'Sugar'],
      preparationTime: '30 mins',
      calories: '450 kcal',
      allergens: ['Dairy', 'Eggs']
    }
  ];

  const virtualTour = {
    rooms: ['Dining Room', 'Wine Cellar', 'Private Dining', 'Kitchen View'],
    images: [
      'https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?auto=format&fit=crop&q=80',
      'https://images.unsplash.com/photo-1528698827591-e19ccd7bc23d?auto=format&fit=crop&q=80',
      'https://images.unsplash.com/photo-1515539408953-9403f070ad2e?auto=format&fit=crop&q=80',
      'https://images.unsplash.com/photo-1556910103-1c02745aae4d?auto=format&fit=crop&q=80'
    ]
  };

  const handleDishClick = (id: string) => {
    setSelectedDish(id === selectedDish ? null : id);
  };

  const nextRoom = () => {
    setCurrentRoom((prev) => (prev + 1) % virtualTour.rooms.length);
  };

  const prevRoom = () => {
    setCurrentRoom((prev) => (prev - 1 + virtualTour.rooms.length) % virtualTour.rooms.length);
  };

  return (
    <div className="min-h-screen bg-gray-900">
      {/* Hero Section */}
      <section className="relative h-screen">
        <div className="absolute inset-0">
          <img
            src="https://images.unsplash.com/photo-1514933651103-005eec06c04b?auto=format&fit=crop&q=80"
            alt="Restaurant Interior"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50" />
        </div>
        <div className="relative z-10 h-full flex items-center justify-center text-center">
          <div className="max-w-4xl mx-auto px-4">
            <h1 className="text-5xl md:text-6xl font-bold text-white mb-6 font-serif">
              La Maison Bistro
            </h1>
            <p className="text-xl text-gray-200 mb-8">
              Experience authentic French cuisine in the heart of the city
            </p>
            <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
              <button 
                onClick={() => setShowBooking(true)}
                className="px-8 py-4 bg-yellow-600 text-white rounded-lg hover:bg-yellow-700 transition-all"
              >
                Reserve a Table
              </button>
              <button 
                onClick={() => setShowVirtualTour(true)}
                className="px-8 py-4 bg-white/10 text-white rounded-lg hover:bg-white/20 transition-all"
              >
                Virtual Tour
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Menu Section */}
      <section className="py-20 bg-gray-800">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-white mb-12 text-center font-serif">
            Featured Dishes
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {dishes.map((dish) => (
              <div 
                key={dish.id}
                className="bg-gray-900 rounded-lg overflow-hidden cursor-pointer"
                onClick={() => handleDishClick(dish.id)}
              >
                <div className="relative h-48">
                  <img
                    src={dish.image}
                    alt={dish.name}
                    className="w-full h-full object-cover"
                  />
                  {selectedDish === dish.id && (
                    <div className="absolute inset-0 bg-black bg-opacity-75 flex items-center justify-center">
                      <div className="text-center p-4">
                        <h4 className="text-white font-bold mb-2">Ingredients:</h4>
                        <ul className="text-gray-300 text-sm">
                          {dish.ingredients.map((ingredient, idx) => (
                            <li key={idx}>{ingredient}</li>
                          ))}
                        </ul>
                        <div className="mt-4 text-sm">
                          <p className="text-yellow-400">{dish.preparationTime} | {dish.calories}</p>
                          <p className="text-gray-400 mt-1">Allergens: {dish.allergens.join(', ')}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="p-6">
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="text-xl font-semibold text-white">{dish.name}</h3>
                    <span className="text-yellow-600">${dish.price}</span>
                  </div>
                  <p className="text-gray-400">{dish.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Virtual Tour Modal */}
      {showVirtualTour && (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-90">
          <div className="relative w-full max-w-4xl">
            <button
              onClick={() => setShowVirtualTour(false)}
              className="absolute top-4 right-4 text-white hover:text-gray-300"
            >
              Close
            </button>
            <div className="relative h-[70vh]">
              <img
                src={virtualTour.images[currentRoom]}
                alt={virtualTour.rooms[currentRoom]}
                className="w-full h-full object-cover rounded-lg"
              />
              <div className="absolute inset-0 flex items-center justify-between p-4">
                <button onClick={prevRoom} className="text-white hover:text-yellow-400">
                  Previous
                </button>
                <button onClick={nextRoom} className="text-white hover:text-yellow-400">
                  Next
                </button>
              </div>
              <div className="absolute bottom-0 left-0 right-0 p-6 bg-gradient-to-t from-black text-center">
                <div className="text-2xl font-bold text-white mb-2">
                  {virtualTour.rooms[currentRoom]}
                </div>
                <div className="text-gray-300">Click and drag to look around</div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Booking Modal */}
      {showBooking && (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-90">
          <div className="bg-gray-900 p-8 rounded-lg max-w-md w-full">
            <h3 className="text-2xl font-bold text-white mb-6">Reserve Your Table</h3>
            <form className="space-y-4">
              <div>
                <label className="block text-gray-300 mb-2">Date</label>
                <input
                  type="date"
                  className="w-full px-4 py-2 bg-gray-800 text-white rounded-lg"
                />
              </div>
              <div>
                <label className="block text-gray-300 mb-2">Time</label>
                <select className="w-full px-4 py-2 bg-gray-800 text-white rounded-lg">
                  <option>5:00 PM</option>
                  <option>6:00 PM</option>
                  <option>7:00 PM</option>
                  <option>8:00 PM</option>
                  <option>9:00 PM</option>
                </select>
              </div>
              <div>
                <label className="block text-gray-300 mb-2">Guests</label>
                <select className="w-full px-4 py-2 bg-gray-800 text-white rounded-lg">
                  {[1, 2, 3, 4, 5, 6].map(num => (
                    <option key={num} value={num}>{num} {num === 1 ? 'Guest' : 'Guests'}</option>
                  ))}
                </select>
              </div>
              <div className="flex justify-end space-x-4 mt-6">
                <button
                  type="button"
                  onClick={() => setShowBooking(false)}
                  className="px-6 py-2 bg-gray-800 text-white rounded-lg"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-6 py-2 bg-yellow-600 text-white rounded-lg hover:bg-yellow-700"
                >
                  Confirm Reservation
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default RestaurantPreview;