import React, { useState } from 'react';
import { Play, Calendar, Clock, Dumbbell, Heart, Award, User } from 'lucide-react';

const workouts = [
  {
    id: 1,
    title: 'Full Body HIIT',
    duration: '30 min',
    level: 'Intermediate',
    calories: '300-400',
    trainer: 'Alex Thompson',
    image: 'https://images.unsplash.com/photo-1549576490-b0b4831ef60a?auto=format&fit=crop&q=80'
  },
  {
    id: 2,
    title: 'Core Strength',
    duration: '20 min',
    level: 'Beginner',
    calories: '150-200',
    trainer: 'Sarah Chen',
    image: 'https://images.unsplash.com/photo-1571019614242-c5c5dee9f50b?auto=format&fit=crop&q=80'
  },
  {
    id: 3,
    title: 'Power Yoga',
    duration: '45 min',
    level: 'All Levels',
    calories: '200-300',
    trainer: 'Maya Patel',
    image: 'https://images.unsplash.com/photo-1544367567-0f2fcb009e0b?auto=format&fit=crop&q=80'
  }
];

const FitnessPreview: React.FC = () => {
  const [selectedWorkout, setSelectedWorkout] = useState<number | null>(null);
  const [showModal, setShowModal] = useState(false);

  const handleStartWorkout = (id: number) => {
    setSelectedWorkout(id);
    setShowModal(true);
  };

  return (
    <div className="min-h-screen bg-gray-900">
      {/* Hero Section */}
      <section className="relative h-[70vh]">
        <div className="absolute inset-0">
          <img
            src="https://images.unsplash.com/photo-1534438327276-14e5300c3a48?auto=format&fit=crop&q=80"
            alt="Fitness Hero"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-gray-900 via-gray-900/80 to-transparent" />
        </div>
        <div className="relative z-10 h-full flex items-center">
          <div className="max-w-6xl mx-auto px-4">
            <h1 className="text-5xl md:text-6xl font-bold text-white mb-6">
              Transform Your Body,
              <br />
              <span className="text-emerald-400">Transform Your Life</span>
            </h1>
            <p className="text-xl text-gray-200 mb-8 max-w-xl">
              Join our community of fitness enthusiasts and start your journey to a healthier lifestyle
            </p>
            <div className="flex gap-4">
              <button className="px-8 py-4 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-all">
                Start Free Trial
              </button>
              <button className="px-8 py-4 bg-white/10 text-white rounded-lg hover:bg-white/20 transition-all">
                View Workouts
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Featured Workouts */}
      <section className="py-20">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-white mb-12">Featured Workouts</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {workouts.map(workout => (
              <div key={workout.id} className="bg-gray-800 rounded-lg overflow-hidden group">
                <div className="relative h-48">
                  <img
                    src={workout.image}
                    alt={workout.title}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity">
                    <div className="absolute inset-0 flex items-center justify-center">
                      <button
                        onClick={() => handleStartWorkout(workout.id)}
                        className="p-4 bg-emerald-600 text-white rounded-full hover:bg-emerald-700 transition-all transform hover:scale-110"
                      >
                        <Play className="w-6 h-6" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-bold text-white mb-2">{workout.title}</h3>
                  <div className="grid grid-cols-2 gap-4 mb-4">
                    <div className="flex items-center text-gray-400">
                      <Clock className="w-4 h-4 mr-2" />
                      {workout.duration}
                    </div>
                    <div className="flex items-center text-gray-400">
                      <Dumbbell className="w-4 h-4 mr-2" />
                      {workout.level}
                    </div>
                    <div className="flex items-center text-gray-400">
                      <Heart className="w-4 h-4 mr-2" />
                      {workout.calories} cal
                    </div>
                    <div className="flex items-center text-gray-400">
                      <User className="w-4 h-4 mr-2" />
                      {workout.trainer}
                    </div>
                  </div>
                  <button
                    onClick={() => handleStartWorkout(workout.id)}
                    className="w-full py-3 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-all"
                  >
                    Start Workout
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Workout Modal */}
      {showModal && selectedWorkout && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4">
            <div className="fixed inset-0 bg-black opacity-75" onClick={() => setShowModal(false)} />
            <div className="relative bg-gray-800 rounded-lg max-w-lg w-full p-8">
              <h3 className="text-2xl font-bold text-white mb-4">
                Start Your Workout
              </h3>
              <p className="text-gray-300 mb-6">
                Get ready to begin your fitness journey! Make sure you:
              </p>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center text-gray-300">
                  <Award className="w-5 h-5 text-emerald-400 mr-3" />
                  Have enough space to move freely
                </li>
                <li className="flex items-center text-gray-300">
                  <Award className="w-5 h-5 text-emerald-400 mr-3" />
                  Keep water nearby
                </li>
                <li className="flex items-center text-gray-300">
                  <Award className="w-5 h-5 text-emerald-400 mr-3" />
                  Wear comfortable clothing
                </li>
              </ul>
              <div className="flex justify-end space-x-4">
                <button
                  onClick={() => setShowModal(false)}
                  className="px-6 py-3 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-all"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    setShowModal(false);
                    // In a real app, this would start the workout
                  }}
                  className="px-6 py-3 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-all"
                >
                  Begin Workout
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Progress Section */}
      <section className="py-20 bg-gray-800">
        <div className="max-w-6xl mx-auto px-4">
          <div className="grid md:grid-cols-3 gap-8">
            {[
              { icon: Calendar, label: 'Workouts Completed', value: '247K+' },
              { icon: Heart, label: 'Active Members', value: '50K+' },
              { icon: Award, label: 'Expert Trainers', value: '25+' }
            ].map((stat, index) => (
              <div key={index} className="text-center p-6 bg-gray-900 rounded-lg">
                <stat.icon className="w-8 h-8 text-emerald-400 mx-auto mb-4" />
                <div className="text-3xl font-bold text-white mb-2">{stat.value}</div>
                <div className="text-gray-400">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default FitnessPreview;