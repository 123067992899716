import React from 'react';
import { Check } from 'lucide-react';
import { pricingPlans } from '../../data/pricing';

const PricingSection: React.FC = () => {
  return (
    <section id="pricing" className="py-20 bg-gray-800">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-white mb-4">Transparent Pricing</h2>
          <p className="text-xl text-gray-300">Invest in your digital presence with our comprehensive packages</p>
          <p className="text-lg text-red-400 font-semibold mt-2">🔥 50% OFF - Limited Time Offer!</p>
        </div>
        
        <div className="grid md:grid-cols-3 gap-8">
          {pricingPlans.map((plan, index) => (
            <div
              key={index}
              className={`relative bg-gray-900 rounded-2xl shadow-xl p-8 ${
                plan.popular ? 'ring-2 ring-purple-500 scale-105' : ''
              }`}
            >
              {plan.popular && (
                <span className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-purple-600 text-white px-4 py-1 rounded-full text-sm font-medium">
                  Most Popular
                </span>
              )}
              
              <div className="text-center mb-8">
                <h3 className="text-2xl font-bold text-white mb-2">{plan.name}</h3>
                <p className="text-gray-400 mb-6">{plan.description}</p>
                <div className="flex flex-col items-center justify-center space-y-1">
                  <span className="text-lg text-gray-500 line-through">
                    ${plan.price.toLocaleString()}
                  </span>
                  <span className="text-4xl font-bold text-purple-400">
                    ${plan.salePrice.toLocaleString()}
                  </span>
                </div>
                <p className="text-sm text-purple-400 mt-2">Save ${(plan.price - plan.salePrice).toLocaleString()}!</p>
              </div>

              <ul className="space-y-4 mb-8">
                {plan.features.map((feature, featureIndex) => (
                  <li key={featureIndex} className="flex items-start">
                    <Check className="w-5 h-5 text-purple-400 mr-3 mt-1 flex-shrink-0" />
                    <span className="text-gray-300">{feature}</span>
                  </li>
                ))}
              </ul>

              <div className="text-center">
                <a
                  href="#contact"
                  className={`inline-block w-full py-4 px-8 rounded-lg font-semibold transition-all duration-300 transform hover:-translate-y-1 ${
                    plan.popular
                      ? 'bg-purple-600 text-white hover:bg-purple-700'
                      : 'bg-gray-700 text-white hover:bg-gray-600'
                  }`}
                >
                  Get Started
                </a>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-16 text-center">
          <p className="text-gray-300">
            Need a custom solution? <a href="#contact" className="text-purple-400 hover:text-purple-300 font-semibold">Contact us</a> for a tailored quote.
          </p>
        </div>
      </div>
    </section>
  );
};

export default PricingSection;