import React from 'react';
import { aiFeatures } from '../../data/ai-features';
import AIFeatureCard from '../AIFeatureCard';
import { Bot, Sparkles } from 'lucide-react';

const AIFeaturesSection: React.FC = () => {
  return (
    <section id="ai-features" className="py-20 bg-gray-900">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-16">
          <div className="flex items-center justify-center mb-4">
            <Bot className="w-8 h-8 text-purple-400 mr-2" />
            <h2 className="text-4xl font-bold text-white">AI Solutions</h2>
          </div>
          <p className="text-xl text-gray-300 mb-4">
            Enhance your website with cutting-edge AI features
          </p>
          <div className="inline-flex items-center px-4 py-2 bg-purple-600/20 rounded-full">
            <Sparkles className="w-5 h-5 text-purple-400 mr-2" />
            <span className="text-purple-300">Powered by Google Gemini Pro</span>
          </div>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {aiFeatures.map((feature) => (
            <AIFeatureCard
              key={feature.id}
              feature={feature}
            />
          ))}
        </div>

        <div className="mt-16 text-center">
          <p className="text-gray-300">
            Need a custom AI solution?{' '}
            <a href="#contact" className="text-purple-400 hover:text-purple-300">
              Let's discuss your requirements
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default AIFeaturesSection;