import React, { useState } from 'react';
import { MapPin, Calendar, User, Heart, Share2, MessageCircle } from 'lucide-react';

const posts = [
  {
    id: 1,
    title: 'Hidden Gems of Bali',
    excerpt: 'Discover secret waterfalls and ancient temples off the beaten path...',
    image: 'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&q=80',
    author: 'Sarah Chen',
    date: 'March 15, 2024',
    location: 'Bali, Indonesia',
    likes: 234,
    comments: 45
  },
  {
    id: 2,
    title: 'A Week in Tokyo',
    excerpt: 'Exploring the perfect blend of tradition and modernity in Japan\'s capital...',
    image: 'https://images.unsplash.com/photo-1540959733332-eab4deabeeaf?auto=format&fit=crop&q=80',
    author: 'Mike Wilson',
    date: 'March 12, 2024',
    location: 'Tokyo, Japan',
    likes: 189,
    comments: 32
  },
  {
    id: 3,
    title: 'Santorini Sunset Guide',
    excerpt: 'The ultimate guide to finding the best sunset viewing spots...',
    image: 'https://images.unsplash.com/photo-1570077188670-e3a8d69ac5ff?auto=format&fit=crop&q=80',
    author: 'Elena Martinez',
    date: 'March 10, 2024',
    location: 'Santorini, Greece',
    likes: 312,
    comments: 67
  }
];

const TravelPreview: React.FC = () => {
  const [likedPosts, setLikedPosts] = useState<number[]>([]);
  const [subscribed, setSubscribed] = useState(false);
  const [email, setEmail] = useState('');

  const handleLike = (postId: number) => {
    setLikedPosts(prev => 
      prev.includes(postId) 
        ? prev.filter(id => id !== postId)
        : [...prev, postId]
    );
  };

  const handleSubscribe = (e: React.FormEvent) => {
    e.preventDefault();
    if (email) {
      setSubscribed(true);
      setEmail('');
    }
  };

  return (
    <div className="min-h-screen bg-gray-900">
      {/* Hero Section */}
      <section className="relative h-[60vh]">
        <div className="absolute inset-0">
          <img
            src="https://images.unsplash.com/photo-1488085061387-422e29b40080?auto=format&fit=crop&q=80"
            alt="Travel Hero"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/60 to-transparent" />
        </div>
        <div className="relative z-10 h-full flex items-center">
          <div className="max-w-6xl mx-auto px-4 text-center">
            <h1 className="text-5xl md:text-6xl font-bold text-white mb-6">
              Wanderlust Chronicles
            </h1>
            <p className="text-xl text-gray-200 mb-8 max-w-2xl mx-auto">
              Stories and guides from travelers around the world
            </p>
            <div className="flex justify-center gap-4">
              <button className="px-8 py-4 bg-teal-600 text-white rounded-lg hover:bg-teal-700 transition-all">
                Start Reading
              </button>
              <button className="px-8 py-4 bg-white/10 text-white rounded-lg hover:bg-white/20 transition-all">
                Share Your Story
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Featured Posts */}
      <section className="py-20">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-white mb-12">Featured Stories</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {posts.map(post => (
              <article key={post.id} className="bg-gray-800 rounded-lg overflow-hidden group">
                <div className="relative h-64 overflow-hidden">
                  <img
                    src={post.image}
                    alt={post.title}
                    className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent opacity-0 group-hover:opacity-100 transition-opacity" />
                </div>
                <div className="p-6">
                  <div className="flex items-center text-sm text-gray-400 mb-4">
                    <MapPin className="w-4 h-4 mr-1" />
                    {post.location}
                  </div>
                  <h3 className="text-xl font-bold text-white mb-2">{post.title}</h3>
                  <p className="text-gray-300 mb-4">{post.excerpt}</p>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center text-sm text-gray-400">
                      <User className="w-4 h-4 mr-1" />
                      {post.author}
                      <Calendar className="w-4 h-4 ml-4 mr-1" />
                      {post.date}
                    </div>
                  </div>
                  <div className="flex items-center justify-between mt-4 pt-4 border-t border-gray-700">
                    <button
                      onClick={() => handleLike(post.id)}
                      className={`flex items-center ${
                        likedPosts.includes(post.id) ? 'text-red-500' : 'text-gray-400'
                      } hover:text-red-500 transition-colors`}
                    >
                      <Heart className={`w-5 h-5 mr-1 ${likedPosts.includes(post.id) ? 'fill-current' : ''}`} />
                      {post.likes + (likedPosts.includes(post.id) ? 1 : 0)}
                    </button>
                    <div className="flex space-x-4">
                      <button className="flex items-center text-gray-400 hover:text-white transition-colors">
                        <MessageCircle className="w-5 h-5 mr-1" />
                        {post.comments}
                      </button>
                      <button className="flex items-center text-gray-400 hover:text-white transition-colors">
                        <Share2 className="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
      </section>

      {/* Newsletter */}
      <section className="py-20 bg-gray-800">
        <div className="max-w-4xl mx-auto px-4 text-center">
          {!subscribed ? (
            <>
              <h2 className="text-3xl font-bold text-white mb-6">
                Join Our Travel Community
              </h2>
              <p className="text-gray-300 mb-8">
                Get weekly travel inspiration, tips, and exclusive deals straight to your inbox
              </p>
              <form onSubmit={handleSubscribe} className="flex max-w-md mx-auto">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="flex-1 px-4 py-3 bg-gray-900 text-white rounded-l-lg focus:outline-none"
                  required
                />
                <button
                  type="submit"
                  className="px-6 py-3 bg-teal-600 text-white rounded-r-lg hover:bg-teal-700 transition-all"
                >
                  Subscribe
                </button>
              </form>
            </>
          ) : (
            <div className="bg-teal-600/20 p-6 rounded-lg">
              <h3 className="text-2xl font-bold text-white mb-2">Welcome Aboard! 🎉</h3>
              <p className="text-gray-300">
                Check your inbox for a confirmation email and exciting travel content!
              </p>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default TravelPreview;