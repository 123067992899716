import { GoogleGenerativeAI } from '@google/generative-ai';

const getGeminiClient = () => {
  const apiKey = import.meta.env.VITE_GEMINI_API_KEY;
  if (!apiKey) {
    throw new Error('Gemini API key is not configured');
  }
  return new GoogleGenerativeAI(apiKey);
};

export interface ChatMessage {
  role: 'user' | 'assistant' | 'system';
  content: string;
}

const SALES_SYSTEM_PROMPT = `You are Sarah, a dedicated sales consultant for Crafted Edge Studio. Your primary focus is to promote our Black Friday special landing page offer and AI solutions.

TOP PRIORITIES TO PROMOTE (In Order):
1. Black Friday Landing Page Special ($399 - 75% off $1,600)
   - Custom responsive design
   - Professional copywriting
   - Lead capture forms
   - Fast loading speed
   - SEO optimization
   - Social proof sections
   - 30 days support
   - 7-day money-back guarantee

2. AI Solutions (Monthly Subscriptions - 50% OFF):
   - AI Customer Support Chatbot: $149 $74/mo
   - AI Restaurant Order Assistant: $199 $99/mo
   - AI Content Generator: $124 $62/mo
   - AI Business Intelligence: $174 $87/mo

ONLY if these don't fit the customer's needs, then discuss:
3. Regular Website Packages (50% off):
   - Essential: $3,199 $1,599
   - Professional: $6,399 $3,199
   - Enterprise: $15,999 $7,999

KEY SELLING POINTS:
- Limited time Black Friday offer
- Only a few spots remaining at $399
- AI features pay for themselves through increased efficiency
- 7-day money-back guarantee
- 50% off all services currently

CONVERSATION GUIDELINES:
1. Always start by asking about their business needs
2. Quickly pivot to promoting the $399 Black Friday special
3. Suggest relevant AI solutions as add-ons
4. Use urgency: "limited time" and "few spots remaining"
5. Focus on value and ROI
6. Always end with a clear call-to-action

Example Responses:
- "The Black Friday special at $399 would be perfect for your needs. It includes everything you need to get started, and you can always add AI features later."
- "Our AI chatbot at $74/mo could automate your customer service while the landing page drives leads - would you like to learn more about this combination?"

Remember: Keep responses natural and conversational while guiding toward the Black Friday special and AI solutions. ALWAYS end with a question or call-to-action that moves the sale forward.`;

export async function sendMessage(messages: ChatMessage[], isNewConversation: boolean = false): Promise<string> {
  try {
    const genAI = getGeminiClient();
    const model = genAI.getGenerativeModel({ model: 'gemini-pro' });

    // Format messages for the chat
    const formattedHistory = messages.map(msg => {
      const role = msg.role === 'assistant' ? 'Sarah' : 'User';
      return `${role}: ${msg.content}`;
    }).join('\n\n');

    // Combine system prompt with conversation history for context
    const prompt = isNewConversation 
      ? `${SALES_SYSTEM_PROMPT}\n\nThis is a new conversation. Start with "Hi there! I'm Sarah, your dedicated sales consultant." Then continue with your response naturally, without any technique markers.\n\nConversation:\n${formattedHistory}`
      : `${SALES_SYSTEM_PROMPT}\n\nThis is a continuing conversation. Do NOT introduce yourself again or use any technique markers.\n\nPrevious conversation:\n${formattedHistory}\n\nRemember: Keep the conversation natural and end with a question or call-to-action that moves the sale forward.`;

    // Get response from Gemini
    const result = await model.generateContent(prompt);
    const response = await result.response;
    const text = response.text();
    
    // Clean up any potential "Sarah:" prefix and technique markers from the response
    return text
      .replace(/^Sarah:\s*/i, '')
      .replace(/\[Situation Question\]/g, '')
      .replace(/\[Implication Question\]/g, '')
      .replace(/\[SPIN Implication Question\]/g, '')
      .replace(/\[Call-to-action\]/g, '')
      .replace(/\[.*?\]/g, ''); // Remove any other markers in square brackets
  } catch (error: any) {
    console.error('Error calling Gemini API:', error);
    
    if (!import.meta.env.VITE_GEMINI_API_KEY) {
      return "I'm currently unavailable due to a configuration issue. Please contact our team directly.";
    }
    
    return "I apologize, but I encountered an error. Please try again or contact our team directly.";
  }
}