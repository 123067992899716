import React, { useState } from 'react';
import { MapPin, Bed, Bath, Square, Heart, Share2, Search } from 'lucide-react';

const properties = [
  {
    id: 1,
    title: 'Modern Downtown Apartment',
    price: 750000,
    location: 'Downtown, New York',
    beds: 2,
    baths: 2,
    sqft: 1200,
    image: 'https://images.unsplash.com/photo-1545324418-cc1a3fa10c00?auto=format&fit=crop&q=80'
  },
  {
    id: 2,
    title: 'Luxury Beach House',
    price: 2500000,
    location: 'Malibu, California',
    beds: 4,
    baths: 3,
    sqft: 3500,
    image: 'https://images.unsplash.com/photo-1512917774080-9991f1c4c750?auto=format&fit=crop&q=80'
  },
  {
    id: 3,
    title: 'Mountain View Villa',
    price: 1800000,
    location: 'Aspen, Colorado',
    beds: 5,
    baths: 4,
    sqft: 4200,
    image: 'https://images.unsplash.com/photo-1600596542815-ffad4c1539a9?auto=format&fit=crop&q=80'
  }
];

const RealEstatePreview: React.FC = () => {
  const [favorites, setFavorites] = useState<number[]>([]);
  const [showContact, setShowContact] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const toggleFavorite = (id: number) => {
    setFavorites(prev =>
      prev.includes(id)
        ? prev.filter(fid => fid !== id)
        : [...prev, id]
    );
  };

  return (
    <div className="min-h-screen bg-gray-900">
      {/* Hero Section */}
      <section className="relative h-[70vh]">
        <div className="absolute inset-0">
          <img
            src="https://images.unsplash.com/photo-1600585154340-be6161a56a0c?auto=format&fit=crop&q=80"
            alt="Luxury Home"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-gray-900 via-gray-900/80 to-transparent" />
        </div>
        <div className="relative z-10 h-full flex items-center">
          <div className="max-w-6xl mx-auto px-4">
            <h1 className="text-5xl md:text-6xl font-bold text-white mb-6">
              Find Your Dream Home
            </h1>
            <p className="text-xl text-gray-200 mb-8 max-w-xl">
              Discover luxury properties in prime locations
            </p>
            <div className="max-w-2xl bg-white/10 backdrop-blur-md p-4 rounded-lg">
              <div className="flex gap-4">
                <input
                  type="text"
                  placeholder="Search by location or property type..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="flex-1 px-4 py-3 bg-gray-900/50 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all flex items-center">
                  <Search className="w-5 h-5 mr-2" />
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Featured Properties */}
      <section className="py-20">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-white mb-12">Featured Properties</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {properties.map(property => (
              <div key={property.id} className="bg-gray-800 rounded-lg overflow-hidden group">
                <div className="relative h-64">
                  <img
                    src={property.image}
                    alt={property.title}
                    className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                  />
                  <div className="absolute top-4 right-4 space-y-2">
                    <button
                      onClick={() => toggleFavorite(property.id)}
                      className={`p-2 rounded-full ${
                        favorites.includes(property.id)
                          ? 'bg-red-500 text-white'
                          : 'bg-white/80 text-gray-900'
                      } hover:scale-110 transition-all`}
                    >
                      <Heart className={`w-5 h-5 ${favorites.includes(property.id) ? 'fill-current' : ''}`} />
                    </button>
                    <button className="p-2 bg-white/80 text-gray-900 rounded-full hover:scale-110 transition-all">
                      <Share2 className="w-5 h-5" />
                    </button>
                  </div>
                </div>
                <div className="p-6">
                  <div className="flex items-center text-blue-400 mb-2">
                    <MapPin className="w-4 h-4 mr-1" />
                    {property.location}
                  </div>
                  <h3 className="text-xl font-bold text-white mb-2">{property.title}</h3>
                  <p className="text-2xl font-bold text-blue-400 mb-4">
                    ${property.price.toLocaleString()}
                  </p>
                  <div className="grid grid-cols-3 gap-4 mb-6">
                    <div className="flex items-center text-gray-400">
                      <Bed className="w-4 h-4 mr-2" />
                      {property.beds} Beds
                    </div>
                    <div className="flex items-center text-gray-400">
                      <Bath className="w-4 h-4 mr-2" />
                      {property.baths} Baths
                    </div>
                    <div className="flex items-center text-gray-400">
                      <Square className="w-4 h-4 mr-2" />
                      {property.sqft} sqft
                    </div>
                  </div>
                  <button
                    onClick={() => setShowContact(true)}
                    className="w-full py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all"
                  >
                    Schedule Viewing
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Contact Modal */}
      {showContact && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4">
            <div className="fixed inset-0 bg-black opacity-75" onClick={() => setShowContact(false)} />
            <div className="relative bg-gray-800 rounded-lg max-w-md w-full p-8">
              <h3 className="text-2xl font-bold text-white mb-4">Schedule a Viewing</h3>
              <form className="space-y-4">
                <div>
                  <label className="block text-gray-300 mb-2">Name</label>
                  <input
                    type="text"
                    className="w-full px-4 py-2 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-gray-300 mb-2">Email</label>
                  <input
                    type="email"
                    className="w-full px-4 py-2 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-gray-300 mb-2">Phone</label>
                  <input
                    type="tel"
                    className="w-full px-4 py-2 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-gray-300 mb-2">Preferred Date</label>
                  <input
                    type="date"
                    className="w-full px-4 py-2 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div className="flex justify-end space-x-4 mt-6">
                  <button
                    type="button"
                    onClick={() => setShowContact(false)}
                    className="px-6 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-all"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all"
                  >
                    Submit Request
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {/* Stats Section */}
      <section className="py-20 bg-gray-800">
        <div className="max-w-6xl mx-auto px-4">
          <div className="grid md:grid-cols-4 gap-8">
            {[
              { label: 'Properties Sold', value: '1,234+' },
              { label: 'Happy Clients', value: '950+' },
              { label: 'Cities Covered', value: '25+' },
              { label: 'Awards Won', value: '15+' }
            ].map((stat, index) => (
              <div key={index} className="text-center">
                <div className="text-3xl font-bold text-blue-400 mb-2">{stat.value}</div>
                <div className="text-gray-300">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default RealEstatePreview;