import React from 'react';
import { templates } from '../data/templates';
import TemplatePreview from './TemplatePreview';
import BlackFridayCheckout from './BlackFridayCheckout';
import { ArrowRight, Star } from 'lucide-react';
import { useAppStore } from '../store';
import { cn } from '../lib/utils';

const TemplateShowcase: React.FC = () => {
  const [showCheckout, setShowCheckout] = React.useState(false);
  const { selectedTemplate, setSelectedTemplate, showPreview, setShowPreview } = useAppStore();

  const handlePreview = (templateId: string) => {
    setSelectedTemplate(templateId);
    setShowPreview(true);
  };

  return (
    <section id="templates" className="py-20 bg-gray-900">
      <div className="max-w-6xl mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-white mb-4">Premium Templates</h2>
          <p className="text-xl text-gray-300">Choose from our professionally designed templates</p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {templates.map((template) => (
            <div
              key={template.id}
              className="bg-gray-800 rounded-lg overflow-hidden group"
            >
              <div className="relative h-64">
                <img
                  src={template.image}
                  alt={template.title}
                  className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity">
                  <div className="absolute bottom-0 left-0 right-0 p-6">
                    <h3 className="text-xl font-bold text-white mb-2">{template.title}</h3>
                    <p className="text-gray-300">{template.description}</p>
                  </div>
                </div>
              </div>
              <div className="p-6">
                <div className="flex items-center justify-between mb-4">
                  <div className="flex text-yellow-400">
                    {[...Array(5)].map((_, i) => (
                      <Star key={i} className="w-4 h-4 fill-current" />
                    ))}
                  </div>
                  <span className="text-gray-400">Conversion Rate: {template.conversionRate}</span>
                </div>
                <div className="flex flex-wrap gap-2 mb-4">
                  {template.features.slice(0, 3).map((feature, index) => (
                    <span
                      key={index}
                      className={cn(
                        "px-3 py-1 rounded-full text-sm",
                        "bg-purple-600/20 text-purple-400"
                      )}
                    >
                      {feature}
                    </span>
                  ))}
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={() => handlePreview(template.id)}
                    className="flex-1 px-4 py-3 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-all"
                  >
                    Live Preview
                  </button>
                  <button
                    onClick={() => setShowCheckout(true)}
                    className={cn(
                      "flex-1 py-3 text-white rounded-lg transition-all flex items-center justify-center",
                      "bg-purple-600 hover:bg-purple-700"
                    )}
                  >
                    Select
                    <ArrowRight className="w-4 h-4 ml-2" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {showPreview && selectedTemplate && (
        <TemplatePreview
          templateId={selectedTemplate}
          onClose={() => setShowPreview(false)}
        />
      )}

      {showCheckout && (
        <BlackFridayCheckout onClose={() => setShowCheckout(false)} />
      )}
    </section>
  );
};

export default TemplateShowcase;