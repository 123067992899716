import React from 'react';
import LegalLayout from './LegalLayout';

const PrivacyPolicy: React.FC = () => {
  return (
    <LegalLayout>
      <div className="prose prose-invert max-w-none">
        <h1 className="text-4xl font-bold text-white mb-8">Privacy Policy</h1>
        <p className="text-gray-300">Last updated: March 11, 2024</p>
        
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-white mb-4">1. Information We Collect</h2>
          <div className="space-y-4 text-gray-300">
            <p>We collect information that you provide directly to us, including:</p>
            <ul className="list-disc pl-6">
              <li>Name and contact information</li>
              <li>Payment information</li>
              <li>Communications with our AI assistant</li>
              <li>Website preferences and settings</li>
            </ul>
          </div>
        </section>

        <section className="mt-8">
          <h2 className="text-2xl font-bold text-white mb-4">2. How We Use Your Information</h2>
          <div className="space-y-4 text-gray-300">
            <p>We use the information we collect to:</p>
            <ul className="list-disc pl-6">
              <li>Provide and maintain our services</li>
              <li>Process your payments</li>
              <li>Send you updates and marketing communications</li>
              <li>Improve our services and develop new features</li>
              <li>Protect against fraud and unauthorized access</li>
            </ul>
          </div>
        </section>

        <section className="mt-8">
          <h2 className="text-2xl font-bold text-white mb-4">3. Information Sharing</h2>
          <div className="space-y-4 text-gray-300">
            <p>We do not sell your personal information. We may share your information with:</p>
            <ul className="list-disc pl-6">
              <li>Service providers who assist in our operations</li>
              <li>Professional advisors</li>
              <li>Law enforcement when required by law</li>
            </ul>
          </div>
        </section>

        <section className="mt-8">
          <h2 className="text-2xl font-bold text-white mb-4">4. Data Security</h2>
          <div className="space-y-4 text-gray-300">
            <p>We implement appropriate security measures to protect your information, including:</p>
            <ul className="list-disc pl-6">
              <li>Encryption of sensitive data</li>
              <li>Regular security assessments</li>
              <li>Access controls and authentication</li>
              <li>Secure data storage and transmission</li>
            </ul>
          </div>
        </section>

        <section className="mt-8">
          <h2 className="text-2xl font-bold text-white mb-4">5. Your Rights</h2>
          <div className="space-y-4 text-gray-300">
            <p>You have the right to:</p>
            <ul className="list-disc pl-6">
              <li>Access your personal information</li>
              <li>Correct inaccurate information</li>
              <li>Request deletion of your information</li>
              <li>Opt-out of marketing communications</li>
              <li>Data portability</li>
            </ul>
          </div>
        </section>

        <section className="mt-8">
          <h2 className="text-2xl font-bold text-white mb-4">6. Contact Us</h2>
          <div className="space-y-4 text-gray-300">
            <p>If you have any questions about this Privacy Policy, please contact us at:</p>
            <p>Email: privacy@craftededgestudio.com</p>
          </div>
        </section>
      </div>
    </LegalLayout>
  );
};

export default PrivacyPolicy;