import React from 'react';
import { ArrowRight, ChevronDown, Sparkles, Zap, Bot, LineChart } from 'lucide-react';

const Hero: React.FC = () => {
  return (
    <section id="home" className="relative min-h-screen flex items-center">
      <div className="absolute inset-0">
        {/* Lighter gradient overlay */}
        <div className="absolute inset-0 bg-gradient-to-br from-gray-900/80 via-gray-800/70 to-gray-900/80 z-10"></div>
        {/* Animated purple accent */}
        <div className="absolute inset-0 bg-gradient-to-br from-purple-600/20 via-purple-500/20 to-transparent mix-blend-soft-light z-20 animate-gradient-slow"></div>
        {/* Moving particles overlay */}
        <div className="absolute inset-0 opacity-30 z-30 bg-[radial-gradient(circle_at_50%_50%,rgba(120,119,198,0.3)_0%,rgba(0,0,0,0)_50%)] animate-pulse-slow"></div>
        {/* Floating orbs */}
        <div className="absolute inset-0 z-20">
          <div className="absolute top-1/4 left-1/4 w-32 h-32 bg-purple-500/10 rounded-full blur-3xl animate-float-slow"></div>
          <div className="absolute bottom-1/3 right-1/3 w-40 h-40 bg-purple-400/10 rounded-full blur-3xl animate-float-slower"></div>
        </div>
        <img
          src="https://images.unsplash.com/photo-1467232004584-a241de8bcf5d?auto=format&fit=crop&q=80&w=2400"
          alt="Hero Background"
          className="w-full h-full object-cover filter brightness-110"
        />
      </div>

      <div className="relative z-30 max-w-7xl mx-auto px-4 py-32">
        <div className="max-w-3xl">
          <div className="flex flex-wrap items-center justify-start gap-4 mb-6">
            <div className="flex items-center space-x-2 bg-purple-900/30 px-4 py-2 rounded-full backdrop-blur-sm">
              <Sparkles className="w-5 h-5 text-purple-400" />
              <span className="text-purple-300 font-semibold">Premium Web Design</span>
            </div>
            <div className="flex items-center space-x-2 bg-purple-900/30 px-4 py-2 rounded-full backdrop-blur-sm">
              <Bot className="w-5 h-5 text-purple-400" />
              <span className="text-purple-300 font-semibold">Smart AI Features</span>
            </div>
          </div>
          <div className="overflow-hidden">
            <h1 className="hero-title text-6xl md:text-7xl font-bold mb-6 leading-tight animate-fade-in">
              <span className="relative inline-block">
                <span className="absolute -inset-1 bg-gradient-to-r from-purple-600/20 to-purple-300/20 blur-xl animate-pulse-slow"></span>
                <span className="relative text-transparent bg-clip-text bg-gradient-to-r from-white via-purple-100 to-purple-300 animate-gradient">
                  Elevate Your Brand
                </span>
              </span>{' '}
              <span className="relative block mt-2">
                <span className="absolute -inset-1 bg-gradient-to-r from-purple-600/20 to-purple-300/20 blur-xl animate-pulse-slow"></span>
                <span className="relative text-transparent bg-clip-text bg-gradient-to-r from-white via-purple-300 to-purple-500 animate-gradient">
                  With Smart Design
                </span>
              </span>
            </h1>
          </div>
          <p className="hero-text text-xl mb-8 font-light leading-relaxed max-w-2xl bg-gradient-to-r from-white via-purple-50 to-purple-200 text-transparent bg-clip-text animate-slide-up">
            Transform your online presence with stunning, high-converting websites powered by intelligent features. 
            Experience the perfect blend of premium design and smart technology.
          </p>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-8">
            <div className="flex items-center space-x-2 text-gray-300">
              <Bot className="w-5 h-5 text-purple-400" />
              <span>Smart Chat Support</span>
            </div>
            <div className="flex items-center space-x-2 text-gray-300">
              <LineChart className="w-5 h-5 text-purple-400" />
              <span>Analytics Insights</span>
            </div>
            <div className="flex items-center space-x-2 text-gray-300">
              <Zap className="w-5 h-5 text-purple-400" />
              <span>Conversion Boost</span>
            </div>
          </div>
          <div className="hero-text flex flex-col sm:flex-row gap-4 animate-fade-in-up">
            <a
              href="#templates"
              className="group relative inline-flex items-center justify-center px-8 py-4 bg-purple-600 text-white rounded-lg transition-all duration-300 transform hover:-translate-y-1 hover:shadow-lg overflow-hidden"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-purple-600 via-purple-500 to-purple-600 animate-gradient-x"></div>
              <span className="relative flex items-center">
                View Our Work
                <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              </span>
            </a>
            <a
              href="#contact"
              className="group inline-flex items-center justify-center px-8 py-4 bg-white/10 text-white rounded-lg hover:bg-white/20 transition-all duration-300 transform hover:-translate-y-1 backdrop-blur-sm hover:shadow-lg"
            >
              Get in Touch
            </a>
          </div>
        </div>
      </div>

      <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 animate-bounce z-30">
        <a 
          href="#templates" 
          className="text-white hover:text-purple-400 transition-colors duration-300 relative group"
          aria-label="Scroll to templates"
        >
          <div className="absolute -inset-2 bg-gradient-to-r from-purple-600/20 to-purple-300/20 rounded-full blur-md opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
          <ChevronDown className="h-8 w-8 relative" />
        </a>
      </div>
    </section>
  );
};

export default Hero;