import React, { useEffect } from 'react';
import { ArrowRight, Shield, Zap, Headphones, Check, Sparkles, Star } from 'lucide-react';

interface GuaranteesStepProps {
  onNext: () => void;
  salePrice: number;
}

const packageIncludes = [
  "Custom responsive design optimized for all devices",
  "Professional copywriting and content optimization",
  "High-converting lead capture forms",
  "Fast loading speed (85+ PageSpeed score)",
  "SEO optimization for better visibility",
  "Social proof and testimonial sections",
  "Call-to-action optimization",
  "30 days of technical support"
];

const guarantees = [
  {
    title: "7-Day Money Back Guarantee",
    description: "Try it risk-free. Not satisfied? Get a full refund within 7 days, no questions asked.",
    icon: <Shield className="w-6 h-6 text-purple-400" />
  },
  {
    title: "Speed Optimization Guarantee",
    description: "We'll optimize your site to achieve at least 85+ PageSpeed score on mobile and 90+ on desktop.",
    icon: <Zap className="w-6 h-6 text-purple-400" />
  },
  {
    title: "Priority Support Guarantee",
    description: "Get direct access to our support team with 24-hour response time for 30 days.",
    icon: <Headphones className="w-6 h-6 text-purple-400" />
  }
];

const GuaranteesStep: React.FC<GuaranteesStepProps> = ({ onNext, salePrice }) => {
  useEffect(() => {
    const modalContent = document.querySelector('.modal-content');
    if (modalContent) {
      modalContent.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  return (
    <div className="space-y-8 modal-content">
      {/* Black Friday Banner */}
      <div className="relative bg-gradient-to-r from-purple-600 via-purple-500 to-purple-600 rounded-lg p-6 text-center">
        <div className="relative">
          <div className="flex items-center justify-center gap-2">
            <Star className="w-5 h-5 text-yellow-400 fill-current" />
            <span className="text-xl font-bold text-yellow-400">BLACK FRIDAY SPECIAL</span>
            <Star className="w-5 h-5 text-yellow-400 fill-current" />
          </div>
        </div>
      </div>

      {/* Package Details */}
      <div className="bg-purple-600/10 rounded-lg p-6 border border-purple-500/30">
        <div className="flex items-center gap-2 mb-4">
          <Sparkles className="w-5 h-5 text-purple-400" />
          <h3 className="text-xl font-bold text-white">Premium Landing Page Package</h3>
        </div>
        <div className="space-y-3">
          {packageIncludes.map((item, index) => (
            <div key={index} className="flex items-start">
              <Check className="w-5 h-5 text-purple-400 mr-2 mt-0.5 flex-shrink-0" />
              <span className="text-gray-300">{item}</span>
            </div>
          ))}
        </div>
        <div className="mt-4 pt-4 border-t border-purple-500/30">
          <div className="flex items-center justify-between">
            <div className="text-gray-400">
              <span className="line-through">Regular Price: $1,600</span>
            </div>
            <div className="text-purple-400 font-bold">
              Save 75% Today!
            </div>
          </div>
        </div>
      </div>

      {/* Pricing and CTA */}
      <div className="text-center py-6 bg-purple-600/20 rounded-lg border border-purple-500/30">
        <div className="text-3xl font-bold text-purple-400 mb-2">Only ${salePrice}</div>
        <div className="text-gray-400 mb-4">Secure Payment - Instant Access</div>
        <button
          onClick={onNext}
          className="px-8 py-4 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors flex items-center justify-center mx-auto font-semibold"
        >
          Secure Your Spot Now <ArrowRight className="ml-2 w-5 h-5" />
        </button>
      </div>

      {/* Guarantees */}
      <div>
        <div className="text-center mb-6">
          <h3 className="text-2xl font-bold text-white mb-2">Triple Guarantee</h3>
          <p className="text-gray-400">You're Protected Every Step of the Way</p>
        </div>

        <div className="space-y-4">
          {guarantees.map((guarantee, index) => (
            <div key={index} className="p-4 bg-gray-800 rounded-lg border border-purple-500/30">
              <div className="flex items-start space-x-4">
                <div className="flex-shrink-0 mt-1">{guarantee.icon}</div>
                <div>
                  <h4 className="text-lg font-semibold text-white mb-2">{guarantee.title}</h4>
                  <p className="text-gray-400">{guarantee.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GuaranteesStep;