import React from 'react';
import { AIFeature } from '../data/ai-features';
import { Check, Sparkles } from 'lucide-react';
import * as Icons from 'lucide-react';
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useNavigate } from 'react-router-dom';

interface AIFeatureCardProps {
  feature: AIFeature;
}

const AIFeatureCard: React.FC<AIFeatureCardProps> = ({ feature }) => {
  const IconComponent = Icons[feature.icon as keyof typeof Icons];
  const navigate = useNavigate();

  const paypalOptions = {
    "client-id": import.meta.env.VITE_PAYPAL_CLIENT_ID || "",
    currency: "USD",
    intent: "subscription",
    vault: true
  };

  return (
    <div className="bg-gray-800 rounded-xl p-6 hover:shadow-xl transition-all duration-300 border border-gray-700 hover:border-purple-500">
      <div className="flex items-center mb-4">
        {IconComponent && (
          <IconComponent className="w-8 h-8 text-purple-400 mr-3" />
        )}
        <h3 className="text-xl font-semibold text-white">{feature.name}</h3>
      </div>
      
      <p className="text-gray-300 mb-4">{feature.description}</p>
      
      <div className="mb-6">
        <div className="flex items-center gap-2 mb-1">
          <span className="text-sm text-gray-500 line-through">${feature.price}/mo</span>
          <span className="bg-purple-500/20 text-purple-300 text-xs px-2 py-1 rounded-full flex items-center">
            <Sparkles className="w-3 h-3 mr-1" />
            50% OFF
          </span>
        </div>
        <div className="text-2xl font-bold text-purple-400">
          ${feature.salePrice}
          <span className="text-sm text-gray-400 font-normal">/month</span>
        </div>
      </div>

      <ul className="space-y-3 mb-6">
        {feature.features.map((item, index) => (
          <li key={index} className="flex items-start">
            <Check className="w-5 h-5 text-purple-400 mr-2 flex-shrink-0 mt-0.5" />
            <span className="text-gray-300 text-sm">{item}</span>
          </li>
        ))}
      </ul>

      <div className="relative z-[90]">
        <PayPalScriptProvider options={paypalOptions}>
          <PayPalButtons
            style={{
              layout: "vertical",
              color: "blue",
              shape: "rect",
              label: "subscribe"
            }}
            createSubscription={(data, actions) => {
              return actions.subscription.create({
                plan_id: feature.planId,
                application_context: {
                  shipping_preference: "NO_SHIPPING"
                }
              });
            }}
            onApprove={async (data, actions) => {
              try {
                console.log("Subscription successful!", data.subscriptionID);
                navigate('/thank-you');
              } catch (err) {
                console.error("Subscription error:", err);
              }
            }}
          />
        </PayPalScriptProvider>
      </div>
    </div>
  );
};

export default AIFeatureCard;