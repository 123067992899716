interface PricingPlan {
  name: string;
  price: number;
  salePrice: number;
  description: string;
  features: string[];
  popular?: boolean;
}

const calculateSalePrice = (price: number) => Math.round(price * 0.5);

export const pricingPlans: PricingPlan[] = [
  {
    name: "Essential",
    price: 3199,
    salePrice: calculateSalePrice(3199),
    description: "Perfect for small businesses getting started",
    features: [
      "Custom responsive design",
      "Up to 5 core pages",
      "Basic SEO optimization",
      "Contact form integration",
      "Mobile-first approach",
      "3 rounds of revisions",
      "2 weeks delivery"
    ]
  },
  {
    name: "Professional",
    price: 6399,
    salePrice: calculateSalePrice(6399),
    description: "Ideal for growing businesses",
    features: [
      "Everything in Essential, plus:",
      "Up to 10 custom pages",
      "Advanced SEO package",
      "CMS integration",
      "Custom animations",
      "E-commerce integration",
      "Premium hosting setup",
      "4 weeks delivery"
    ],
    popular: true
  },
  {
    name: "Enterprise",
    price: 15999,
    salePrice: calculateSalePrice(15999),
    description: "For large-scale custom projects",
    features: [
      "Everything in Professional, plus:",
      "Unlimited custom pages",
      "Custom feature development",
      "Advanced analytics",
      "Priority support",
      "Performance optimization",
      "API integration",
      "8-12 weeks delivery"
    ]
  }
];