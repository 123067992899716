import React, { useState, useRef, useEffect } from 'react';
import { X, Shield, Zap, Headphones, Check, Sparkles, Star } from 'lucide-react';
import GuaranteesStep from './checkout/GuaranteesStep';
import PaymentStep from './checkout/PaymentStep';
import SocialProof from './checkout/SocialProof';

interface BlackFridayCheckoutProps {
  onClose: () => void;
}

const BlackFridayCheckout: React.FC<BlackFridayCheckoutProps> = ({ onClose }) => {
  const [step, setStep] = useState<'guarantees' | 'payment'>('guarantees');
  const salePrice = 399;
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [step]);

  return (
    <div className="fixed inset-0 z-[9999] overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" aria-hidden="true" onClick={onClose}></div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div className="relative inline-block align-bottom bg-gray-900 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
          <div className="absolute top-4 right-4">
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-300 focus:outline-none"
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          <div className="p-8 overflow-y-auto max-h-[80vh]" ref={modalRef}>
            {step === 'guarantees' && (
              <GuaranteesStep 
                onNext={() => setStep('payment')} 
                salePrice={salePrice}
              />
            )}
            
            {step === 'payment' && (
              <PaymentStep 
                salePrice={salePrice}
                onBack={() => setStep('guarantees')}
              />
            )}

            <div className="mt-8">
              <SocialProof />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlackFridayCheckout;