import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CheckCircle, FileText, Image, Mail, ArrowRight, Clock, Palette, MessageSquare, Zap, Bot, LineChart, FileSpreadsheet } from 'lucide-react';
import { aiFeatures } from '../data/ai-features';

const ThankYouPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-purple-900/20 to-gray-900">
      <div className="max-w-4xl mx-auto px-4 py-20">
        {/* Success Message */}
        <div className="text-center mb-16">
          <div className="inline-flex items-center justify-center w-16 h-16 bg-green-500/20 rounded-full mb-6">
            <CheckCircle className="w-8 h-8 text-green-400" />
          </div>
          <h1 className="text-4xl font-bold text-white mb-4">Thank You for Your Purchase! 🎉</h1>
          <p className="text-xl text-gray-300">We're excited to start working on your website</p>
        </div>

        {/* Value-Stack Upsells */}
        <div className="bg-purple-900/20 rounded-2xl p-8 mb-12 backdrop-blur-sm border border-purple-500/20">
          <h2 className="text-2xl font-bold text-white mb-2">Exclusive One-Time Offers</h2>
          <p className="text-gray-300 mb-8">Enhance your website with these powerful AI features at special post-purchase pricing</p>
          
          <div className="space-y-6">
            {aiFeatures.map((feature) => (
              <div key={feature.id} className="bg-gray-800/50 rounded-lg p-6 border border-purple-500/20">
                <div className="flex items-start justify-between mb-4">
                  <div className="flex items-start">
                    {feature.icon === 'MessageSquare' && <Bot className="w-6 h-6 text-purple-400 mr-3" />}
                    {feature.icon === 'UtensilsCrossed' && <Zap className="w-6 h-6 text-purple-400 mr-3" />}
                    {feature.icon === 'FileText' && <FileText className="w-6 h-6 text-purple-400 mr-3" />}
                    {feature.icon === 'LineChart' && <FileSpreadsheet className="w-6 h-6 text-purple-400 mr-3" />}
                    <div>
                      <h3 className="text-xl font-bold text-white">{feature.name}</h3>
                      <p className="text-gray-300">{feature.description}</p>
                    </div>
                  </div>
                  <div className="text-right">
                    <div className="text-sm text-gray-500 line-through">${feature.price}/mo</div>
                    <div className="text-2xl font-bold text-purple-400">${feature.salePrice}/mo</div>
                  </div>
                </div>

                <div className="bg-purple-900/20 p-4 rounded-lg mb-4">
                  <h4 className="text-purple-400 font-semibold mb-2">Value Stack:</h4>
                  <ul className="space-y-2">
                    {feature.features.map((item, index) => (
                      <li key={index} className="flex items-center text-gray-300">
                        <Zap className="w-4 h-4 text-purple-400 mr-2 flex-shrink-0" />
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>

                <button className="w-full py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-all flex items-center justify-center">
                  Add to Your Site
                  <ArrowRight className="ml-2 w-5 h-5" />
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* Next Steps */}
        <div className="bg-gray-800/50 rounded-2xl p-8 mb-12 backdrop-blur-sm border border-purple-500/20">
          <h2 className="text-2xl font-bold text-white mb-6">Next Steps</h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="space-y-6">
              <div className="flex items-start">
                <div className="flex-shrink-0 w-10 h-10 bg-purple-600/20 rounded-lg flex items-center justify-center mr-4">
                  <FileText className="w-5 h-5 text-purple-400" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-white mb-2">Project Brief</h3>
                  <p className="text-gray-300">Please fill out our project questionnaire to help us understand your vision and requirements.</p>
                </div>
              </div>

              <div className="flex items-start">
                <div className="flex-shrink-0 w-10 h-10 bg-purple-600/20 rounded-lg flex items-center justify-center mr-4">
                  <Image className="w-5 h-5 text-purple-400" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-white mb-2">Brand Assets</h3>
                  <p className="text-gray-300">Send us your logo, brand colors, and any images you'd like to use on your website.</p>
                </div>
              </div>

              <div className="flex items-start">
                <div className="flex-shrink-0 w-10 h-10 bg-purple-600/20 rounded-lg flex items-center justify-center mr-4">
                  <Clock className="w-5 h-5 text-purple-400" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-white mb-2">Timeline</h3>
                  <p className="text-gray-300">We'll schedule a kick-off call to discuss the project timeline and milestones.</p>
                </div>
              </div>
            </div>

            <div className="space-y-6">
              <div className="flex items-start">
                <div className="flex-shrink-0 w-10 h-10 bg-purple-600/20 rounded-lg flex items-center justify-center mr-4">
                  <Palette className="w-5 h-5 text-purple-400" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-white mb-2">Design Preferences</h3>
                  <p className="text-gray-300">Share examples of websites you like and any specific design preferences.</p>
                </div>
              </div>

              <div className="flex items-start">
                <div className="flex-shrink-0 w-10 h-10 bg-purple-600/20 rounded-lg flex items-center justify-center mr-4">
                  <MessageSquare className="w-5 h-5 text-purple-400" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-white mb-2">Content</h3>
                  <p className="text-gray-300">Prepare your website content, including text, services, and pricing information.</p>
                </div>
              </div>

              <div className="flex items-start">
                <div className="flex-shrink-0 w-10 h-10 bg-purple-600/20 rounded-lg flex items-center justify-center mr-4">
                  <Mail className="w-5 h-5 text-purple-400" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-white mb-2">Contact Info</h3>
                  <p className="text-gray-300">Provide contact details and social media links to be displayed on your website.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* What's Next Box */}
        <div className="bg-purple-600/10 rounded-2xl p-8 border border-purple-500/20 backdrop-blur-sm">
          <h2 className="text-2xl font-bold text-white mb-4">What Happens Now?</h2>
          <div className="space-y-4 text-gray-300 mb-8">
            <p>1. You'll receive a welcome email within the next hour with our project questionnaire.</p>
            <p>2. Once you submit the questionnaire, we'll schedule your kick-off call.</p>
            <p>3. Our team will begin work on your project within 24-48 hours.</p>
            <p>4. You'll have access to our project management system to track progress.</p>
          </div>
          <div className="flex flex-col sm:flex-row gap-4">
            <a 
              href="mailto:support@craftededgestudio.com"
              className="inline-flex items-center justify-center px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-all"
            >
              Contact Support
              <ArrowRight className="ml-2 w-5 h-5" />
            </a>
            <Link
              to="/"
              className="inline-flex items-center justify-center px-6 py-3 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-all"
            >
              Return Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;