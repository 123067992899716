import { create } from 'zustand';

interface AppState {
  selectedTemplate: string | null;
  setSelectedTemplate: (template: string | null) => void;
  showPreview: boolean;
  setShowPreview: (show: boolean) => void;
  spotsRemaining: number;
  decrementSpots: () => void;
}

export const useAppStore = create<AppState>((set) => ({
  selectedTemplate: null,
  setSelectedTemplate: (template) => set({ selectedTemplate: template }),
  showPreview: false,
  setShowPreview: (show) => set({ showPreview: show }),
  spotsRemaining: 10,
  decrementSpots: () => set((state) => ({ 
    spotsRemaining: Math.max(1, state.spotsRemaining - 1)
  })),
}));