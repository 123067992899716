import React from 'react';
import ContactForm from '../ContactForm';

const ContactSection: React.FC = () => {
  return (
    <section id="contact" className="py-20 bg-gray-900">
      <div className="max-w-3xl mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-white mb-4">Get in Touch</h2>
          <p className="text-xl text-gray-300">Let's discuss your project and make it happen</p>
        </div>
        <ContactForm />
      </div>
    </section>
  );
};

export default ContactSection;