import React from 'react';
import LegalLayout from './LegalLayout';

const RefundPolicy: React.FC = () => {
  return (
    <LegalLayout>
      <div className="prose prose-invert max-w-none">
        <h1 className="text-4xl font-bold text-white mb-8">Refund Policy</h1>
        <p className="text-gray-300">Last updated: March 11, 2024</p>

        <section className="mt-8">
          <h2 className="text-2xl font-bold text-white mb-4">1. Refund Eligibility</h2>
          <div className="space-y-4 text-gray-300">
            <p>We offer refunds under the following conditions:</p>
            <ul className="list-disc pl-6">
              <li>Within 7 days of purchase</li>
              <li>Before any custom work has begun</li>
              <li>If we fail to deliver as promised</li>
              <li>For duplicate charges or billing errors</li>
            </ul>
          </div>
        </section>

        <section className="mt-8">
          <h2 className="text-2xl font-bold text-white mb-4">2. Non-Refundable Items</h2>
          <div className="space-y-4 text-gray-300">
            <p>The following are not eligible for refunds:</p>
            <ul className="list-disc pl-6">
              <li>Custom development work that has begun</li>
              <li>Delivered digital products</li>
              <li>Services already rendered</li>
              <li>Third-party services or licenses</li>
            </ul>
          </div>
        </section>

        <section className="mt-8">
          <h2 className="text-2xl font-bold text-white mb-4">3. Refund Process</h2>
          <div className="space-y-4 text-gray-300">
            <p>To request a refund:</p>
            <ul className="list-disc pl-6">
              <li>Contact our support team</li>
              <li>Provide order details and reason for refund</li>
              <li>Allow 5-10 business days for processing</li>
              <li>Refunds will be issued to the original payment method</li>
            </ul>
          </div>
        </section>

        <section className="mt-8">
          <h2 className="text-2xl font-bold text-white mb-4">4. Contact Us</h2>
          <div className="space-y-4 text-gray-300">
            <p>For refund requests or questions, please contact us at:</p>
            <p>Email: support@craftededgestudio.com</p>
          </div>
        </section>
      </div>
    </LegalLayout>
  );
};

export default RefundPolicy;