import React from 'react';
import { Star, Award, TrendingUp, Users } from 'lucide-react';

const testimonials = [
  {
    quote: "The landing page paid for itself in the first week. We saw a 156% increase in conversions!",
    author: "Sarah Chen",
    role: "Marketing Director",
    company: "TechFlow Inc."
  },
  {
    quote: "Best investment we made this year. Our bounce rate dropped by 45% and leads doubled.",
    author: "Michael Rodriguez",
    role: "Founder",
    company: "GrowthLabs"
  }
];

const metrics = [
  {
    label: "Average ROI",
    value: "312%",
    icon: <TrendingUp className="w-5 h-5 text-green-400" />
  },
  {
    label: "Client Success Rate",
    value: "94%",
    icon: <Award className="w-5 h-5 text-yellow-400" />
  },
  {
    label: "Happy Customers",
    value: "1,000+",
    icon: <Users className="w-5 h-5 text-blue-400" />
  }
];

const SocialProof: React.FC = () => {
  return (
    <div className="space-y-8 py-8">
      {/* Trust Metrics */}
      <div className="grid grid-cols-3 gap-4">
        {metrics.map((metric, index) => (
          <div key={index} className="text-center p-4 bg-gray-800/50 rounded-lg">
            <div className="flex justify-center mb-2">{metric.icon}</div>
            <div className="text-2xl font-bold text-white mb-1">{metric.value}</div>
            <div className="text-sm text-gray-400">{metric.label}</div>
          </div>
        ))}
      </div>

      {/* Testimonials */}
      <div className="grid md:grid-cols-2 gap-6">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="p-6 bg-gray-800/50 rounded-lg relative">
            <div className="absolute top-4 right-4">
              <div className="flex text-yellow-400">
                {[...Array(5)].map((_, i) => (
                  <Star key={i} className="w-4 h-4 fill-current" />
                ))}
              </div>
            </div>
            <blockquote className="text-gray-300 mb-4">"{testimonial.quote}"</blockquote>
            <div>
              <div className="font-semibold text-white">{testimonial.author}</div>
              <div className="text-sm text-gray-400">{testimonial.role}, {testimonial.company}</div>
            </div>
          </div>
        ))}
      </div>

      {/* Trust Badges */}
      <div className="flex justify-center space-x-8 py-4">
        <img src="https://cdn.jsdelivr.net/gh/simple-icons/simple-icons/icons/stripe.svg" alt="Stripe" className="h-8 w-8 opacity-50 hover:opacity-75 transition-opacity" />
        <img src="https://cdn.jsdelivr.net/gh/simple-icons/simple-icons/icons/googleanalytics.svg" alt="Google Analytics" className="h-8 w-8 opacity-50 hover:opacity-75 transition-opacity" />
        <img src="https://cdn.jsdelivr.net/gh/simple-icons/simple-icons/icons/cloudflare.svg" alt="Cloudflare" className="h-8 w-8 opacity-50 hover:opacity-75 transition-opacity" />
      </div>
    </div>
  );
};

export default SocialProof;